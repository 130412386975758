import React, { useState, useEffect } from "react";
import right from "../../assets/images/rightArrow.svg";
import left from "../../assets/images/leftArrow.svg";
import arrowleft from "../../assets/images/arrow-left.svg";
import arrowright from "../../assets/images/arrow-right.svg";
import {
  getYear,
  getMonth,
  getCurrentMonth,
  getWeekdays,
  getWeekdaysByMonth,
  getMonthIndexByName,
  getMonthNameByIndex,
  increaseYear,
  decreaseYear,
} from "../../utils/utils";
import DynamicWeekBlock from "./DynamicWeekBlock";
function DynamicCalender() {
  const [data, setData] = useState({});
  const [currentYear, setCurrentYear] = useState(getYear());
  const [currentMonth, setCurrentMonth] = useState(getCurrentMonth());
  const [shortMonth,setShortMonth] = useState();
  const [loading,setLoading] = useState(true);
  const [selectedView,setSelectedView] = useState();
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch("https://events.jiwcalendar.com/api/forms/2", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result.markets[0].dynamicCalendar[currentYear].months[currentMonth]);
        setData(result.markets[0].dynamicCalendar)
        setSelectedView(result.markets[0].dynamicCalendar[currentYear].months[`${currentMonth}`]);
      }
        )

      .catch(error => console.log('error', error));
  }, []);

  useEffect(()=> {
    //setLoading(false);
    console.log(currentMonth);
    if(data){
        console.log('selected day',data['2023']);
        
    }
  },[data])

  useEffect(() => {
    setLoading(false);
  },[selectedView])

  function nextmonth() {
    setLoading(true);
    let monthIndex = currentMonth;
    if (monthIndex < 11) {
      monthIndex += 1;
    } else {
      monthIndex = 0;
      //setCurrentYear(increaseYear(currentYear));
    }
    setCurrentMonth(monthIndex);
    setSelectedView(data[currentYear]['months'][`${monthIndex}`]);
  }

  function prevmonth() {
    let monthIndex = currentMonth;
    if (monthIndex > 0) {
      monthIndex -= 1;
    } else {
      monthIndex = 11;
      //setCurrentYear(decreaseYear(currentYear));
    }
    setCurrentMonth(monthIndex);
    setSelectedView(data[currentYear]['months'][`${monthIndex}`]);
  }
  return (
    <>
    {loading && <div>Loading</div>}
    {!loading && selectedView &&
    <section className="widthSection">
      <p className="text-center mb-0">{currentYear}</p>
      <div className="text-center ">
        <img src={left} alt="" className="pe-5 swipeBtn" onClick={prevmonth} />

        <span className="calenderText pt-3">{selectedView.short_month}</span>
        <img src={right} alt="" className="ps-5 swipeBtn" onClick={nextmonth} />
      </div>

  <div class="outer-wrapper">
    <div class="inner-wrapper">
    <div className="maxWidth ">
        <div className="mt-4 overFlow d-flex">
        {
              selectedView.days.map((item, index) => (
                <DynamicWeekBlock key={index} short_month={selectedView.short_month} short_day={item.short_day} day_of_month={item.day_of_month} times={item.times} />
              ))}
        </div>
      </div>
    </div>
  </div>
  <div className="handIcon">
  <img src={arrowleft} alt="handicon"  width="50" height="50"/>
  <span>Scroll left and right</span>
  <img src={arrowright} alt="handicon"  width="50" height="50"/>
  </div>
  <div class="pseduo-track"></div>
  


    </section>}
    </>
    
  );
}

export default DynamicCalender;
