import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Confirmation from './components/book appointment/Confirmation';
import EmailConfirmation from './components/book appointment/EmailConfirmation';
import BookAppointment from './components/book appointment/BookAppointment';
import AppointmentContext, { AppointmentContextProvider } from "./store/appointment-context";
function App() {
  return (
    <div className="App">
       <BrowserRouter>
       <AppointmentContextProvider>
      <Routes>
      <Route path="/" element={<BookAppointment />} />
      <Route path="/confirmation/:id" element={<Confirmation />} />
      <Route path="/emailconfirmation/:id" element={<EmailConfirmation />} />
    </Routes>
    </AppointmentContextProvider>
   </BrowserRouter>
    </div>
  );
}

export default App;
