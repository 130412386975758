import React from "react";
import logo from "../../assets/images/logo.svg";
import bride from "../../assets/images/bride.svg";
import brideMobile from "../../assets/images/brideMobile.svg";
import layer from "../../assets/images/layer.svg";
import layerMobile from "../../assets/images/layerMobile.svg";
import AppointmentModal from "./AppointmentModal";
import brides from "../../assets/images/bridess.svg";
import footer from "../../assets/images/footer.svg";
import pat from "../../assets/images/pat.svg";
import { useState, useEffect } from "react";

function BookAppointment() {
 



  return (
    <>
      <div className="bookBackground">
        <div className="brideBackground position-relative">
          <div className="position-modal position-absolute">
            <AppointmentModal/>
          </div>
          <div className="parent">
            <img src={bride} alt="bride" className="bride-cover image1" />
            <img src={brideMobile} alt="bride" className="bride-mobile" />
            <img src={layer} alt="layer" className="bride-cover image2" />
            <img src={layerMobile} alt="layer" className="layerMobile" />
            <img src={logo} alt="img" className="img3" />
            <p className="text-title">Book an In-Store Visit</p>
            <p className="text ">
              We schedule our in-person store visits on Sunday's or Monday's.
              When would you like us to visit your store and present you our new
              collection?
            </p>
          </div>

          <div className="paternDiv"></div>
          <img src={pat} alt="pattern" className="pattern bri" />

          <img src={brides} alt="pattern" className="bri" />
          <img src={footer} alt="pattern" className="bri" />
        </div>
      </div>
    </>
  );
}

export default BookAppointment;
