import React from "react";
import DynamicTimeBtn from "./DynamicTimeBtn";

function DynamicWeekBlock({short_month,short_day,day_of_month,times}) {
return (
    <>
<div className="col ps-0 pe-0 widthMobile">
          <p className="mb-0 font-days text-center">{short_day}</p>
          <p className="mb-0 font-days text-center">{short_month}</p>
          <p className="font-days text-center">{day_of_month}</p>
          <div className="text-center">
          {
            times.map((item, index) => (
                <DynamicTimeBtn 
                key={index} 
                booked={item.booked} 
                day_of_month={item.day_of_month} 
                value_date={item.value_date} 
                value_time={item.value_time} 
                hr={item.hr} />
              ))
          }
          </div>
          
</div>

        </> 
        );
}
export default DynamicWeekBlock;