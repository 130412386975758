export function getYear(){
    return new Date().getFullYear();
  }

export function getCurrentMonth() {
  return new Date().getMonth();
}

  export function increaseYear(year){
    return Number(year) + 1;
  }

  export function decreaseYear(year){
    return Number(year) - 1;
  }
  
  export function getMonth() {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  
  const d = new Date();
  let name = month[d.getMonth()];
    return name;
  }
  
  export function getWeekdays() {
    var d = new Date();
    var getTot = daysInMonth(d.getMonth(),d.getFullYear());

 
   var sat = new Array();
   var sun = new Array();
   var mon = new Array();
  
  for(var i=1;i<=getTot;i++){
      var newDate = new Date(d.getFullYear(),d.getMonth(),i)
      if(newDate.getDay()==0){
          sun.push(i)
      }
      if(newDate.getDay()==6){
          sat.push(i)
      }
      if(newDate.getDay()==1){
        mon.push(i)
    }
      
  }
  return {
    sun,
    mon
  }
  
  }

  export function getWeekdaysByMonth(monthindex,year) {
    var d = new Date();
    var getTot = daysInMonth(monthindex,year);

 
   var sat = new Array();
   var sun = new Array();
   var mon = new Array();
  
  for(var i=1;i<=getTot;i++){
      var newDate = new Date(year,monthindex,i)
      if(newDate.getDay()==0){
          sun.push(i)
      }
      if(newDate.getDay()==6){
          sat.push(i)
      }
      if(newDate.getDay()==1){
         mon.push(i)
    }
      
  }
  return {
    sun,
    mon
  }
  
  }
  function daysInMonth(month,year) {
      return new Date(year, month, 0).getDate();
  }

  export function getMonthIndexByName(m) {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return month.indexOf(m);
  }

  export function getMonthNameByIndex(index) {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return month[index];
  }