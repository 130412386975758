import React, { useState, useEffect, useContext } from "react";
import AppointmentContext from "../../store/appointment-context"
function DynamicTimeBtn({booked,value_date,value_time,hr}) {
  const AppointmentCtx = useContext(AppointmentContext);
  const [activeBtn, setActiveBtn] = useState(booked);

  function toogleBtn(e) {
    //var elems = document.querySelectorAll(".active");

 /*for(let elem of elems){
        elem.classList.remove('active');
        }*/
    if(activeBtn){ 
      setActiveBtn(false);
      console.log('unselected time',e.target.value);
      console.log('unselected date',e.target.getAttribute('data-date'));
      AppointmentCtx.setSelectedDay(
        AppointmentCtx.selectedDay.filter(a => 
         a.value_mixed !== e.target.getAttribute('data-mixed')
        )
      );

    }
    else{
      setActiveBtn(true);
      console.log('selected time',e.target.value);
      console.log('selected date',e.target.getAttribute('data-date'));
      
      AppointmentCtx.setSelectedDay([ // with a new array
      ...AppointmentCtx.selectedDay, // that contains all the old items
      {
        "selected_date":e.target.getAttribute('data-date'),
        "selected_time":e.target.value,
        "value_mixed":e.target.getAttribute('data-mixed')
      
      }]) // and one new item at the end
    }
    
  }

  
  if (activeBtn) {
    return (
      <button
        className="timeBtn mb-2 active ms-2"
        disabled={booked}
        onClick={toogleBtn}
        type="button"
        value={value_time}
        data-date={value_date}
        data-mixed={`${value_date}${value_time}`}
      >
        {hr}
      </button>
    );
  } else {
    return (
      <button
        className="timeBtn mb-2 ms-2"
        disabled={booked}
        onClick={toogleBtn}
        type="button"
        value={value_time}
        data-date={value_date}
        data-mixed={`${value_date}${value_time}`}
      >
        {hr}
      </button>
    );
  }
}

export default DynamicTimeBtn;
