import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Form } from "react-bootstrap";
import FormInput from "./FormInput";
import DynamicCalender from "./DynamicCalendar";
import AppointmentContext from "../../store/appointment-context"

function AppointmentModal({data}) {
  const AppointmentCtx = useContext(AppointmentContext);
    useEffect(()=> {
      //AppointmentCtx.setData(data);
    },[])
  return (
    <Container className="py-4 z-index2">
      <div className="center">
        <div className=" modal-dialog form-dialog m-0">
          <div className="modal-content auto-modal">
            <div className="modal-header">
              <h4 className="title">Store Details</h4>
            </div>

            <div className="modal-body">
              <Form
                //   onSubmit={(e) => {
                //     e.preventDefault();
                //     validation.handleSubmit();
                //   }}
                className="row g-3"
              >
                <div className="col-md-6 pe-2">
                  <FormInput
                    type="text"
                    name="store"
                    labelText="STORE NAME"
                    placeholder="Enter Store Name"
                    onChange={() => AppointmentCtx.setError1('')}
                    onBlur={(e) => {(AppointmentCtx.validateIfEmpty(e.target)) 
                      ? AppointmentCtx.setError1('Store Name is required') 
                      : AppointmentCtx.setError1(''); AppointmentCtx.setStoreName(e.target.value);
                    }
                  }
                  />
                  {
                    AppointmentCtx.error1 &&
                    <p className="label-error d-flex  pt-1">
                            {AppointmentCtx.error1}
                    </p>
                  }
                </div>
                <div className="col-md-6 ps-2">
                  <FormInput
                    type="text"
                    name="address"
                    labelText="STORE ADDRESS"
                    placeholder="Enter Store Address"
                    onChange={() => AppointmentCtx.setError2('')}
                    onBlur={(e) => {(AppointmentCtx.validateIfEmpty(e.target)) 
                      ? AppointmentCtx.setError2('Store Address is required') 
                      : AppointmentCtx.setError2(''); AppointmentCtx.setStoreAddress(e.target.value);
                    }
                  }
                  />
                  {
                    AppointmentCtx.error2 &&
                    <p className="label-error d-flex  pt-1">
                            {AppointmentCtx.error2}
                    </p>
                 }
                </div>
                <div className="col-md-6 pe-2">
                  <FormInput
                    type="text"
                    name="name"
                    labelText="NAME"
                    placeholder="Enter your Name"
                    onChange={() => AppointmentCtx.setError3('')}
                    onBlur={(e) => {(AppointmentCtx.validateIfEmpty(e.target)) 
                      ? AppointmentCtx.setError3('Your name is required') 
                      : AppointmentCtx.setError3(''); AppointmentCtx.setPersonName(e.target.value);
                    }
                  }
                  />
                  {
                    AppointmentCtx.error3 &&
                    <p className="label-error d-flex  pt-1">
                            {AppointmentCtx.error3}
                    </p>
                  }
                </div>

                <div className="col-md-6 ps-2">
                  <FormInput
                    type="text"
                    name="email"
                    labelText="EMAIL"
                    placeholder="Enter your Email Address"
                    onChange={() => AppointmentCtx.setError4('')}
                    onBlur={(e) => {(AppointmentCtx.validateIfEmail(e.target)) 
                      ? AppointmentCtx.setError4('Please put a valid email address') 
                      : AppointmentCtx.setError4(''); AppointmentCtx.setStoreEmail(e.target.value);
                    }
                }
                  />
                  {
                  AppointmentCtx.error4 &&
                  <p className="label-error d-flex  pt-1">
                          {AppointmentCtx.error4}
                  </p>
                  }
                </div>
                <div className="col-md-6 pe-2">
                  <FormInput
                    type="text"
                    name="number"
                    labelText="PHONE NUMBER"
                    placeholder="Enter your Phone Number"
                    value={AppointmentCtx.storePhoneNumber}
                    onChange={(e) => {
                      //const result = e.target.value.replace(/\D/g, '');
                      var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                      const result = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                      AppointmentCtx.setStorePhoneNumber(result);
                      AppointmentCtx.setError5('');
                    }}
                    onBlur={(e) => {(AppointmentCtx.validatePhone(e.target)) 
                      ? AppointmentCtx.setError5('Your phone number is required') 
                      : AppointmentCtx.setError5(''); AppointmentCtx.setStorePhoneNumber(e.target.value);
                    }}
                  />

                  {
                              AppointmentCtx.error5 &&
                              <p className="label-error d-flex  pt-1">
                                      {AppointmentCtx.error5}
                              </p>
                  }
                </div>
                <div className="col-md-6 ps-2">
                  <FormInput
                    type="text"
                    name="position"
                    labelText="POSITION"
                    placeholder="Enter your Position"
                    onChange={() => AppointmentCtx.setError6('')}
                    onBlur={(e) => {(AppointmentCtx.validateIfEmpty(e.target)) 
                      ? AppointmentCtx.setError6('Your Position is required') 
                      : AppointmentCtx.setError6(''); AppointmentCtx.setStorePosition(e.target.value);
                    }}
                  />

                  {
                    AppointmentCtx.error6 &&
                    <p className="label-error d-flex  pt-1">
                            {AppointmentCtx.error6}
                    </p>
                  }
                </div>
                <p className="request">
                Please select all of your preferred times for a store visit, and we will call you to schedule one specific time (out of your preferred times) for our visit:
                </p>
                <div className="overFlow gy-0">
                  <DynamicCalender /> 
                </div>
              
                <div className="modal-footer d-block">
                  <div className=" mt-4 justify-content-between section-display">
                    <p className="text-confirm">
                      This appointment is not booked until we call you to
                      confirm. We will give you a call very soon
                    </p>
                    <Button className="btn-request rounded-pill" type="submit" onClick={(e) => {
          e.preventDefault();
          AppointmentCtx.submitAppointment();
        }}>
                      REQUEST AN APPOINTMENT
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AppointmentModal;

function FormatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}
