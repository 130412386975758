import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const AppointmentContext = createContext({
    data:{},
    setData:{},
    marketIndex:'',
    setMarketIndex:'',
    displayStoreDetails: false,
    selectedDates:[],
    setSelectedDates:[],
    displayTimes:false,
    selectedTimes:[],
    setSelectedTimes:[],
    errors:[],
    successMessage:'',
    error1:'',
    error2:'',
    error3:'',
    error4:'',
    error5:'',
    error6:'',
    selectedMarket:'',
    setSelectedMarket:'',
    selectedDay:'',
    setSelectedDay:'',
    selectedTime:'',
    setSelectedTime:'',
    storeName:'',
    setStoreName:'',
    storeAddress:'',
    setStoreAddress:'',
    personName:'',
    setPersonName:'',
    storeEmail:'',
    setStoreEmail:'',
    storePhoneNumber: '',
    setPhoneNumber: '',
    storePosition:'',
    setStorePosition:'',
    validateIfEmpty: (onject) => {},
    validatePhone: (object) => {},
    validateIfEmail: (object) => {},
    handleMarketSelect: (e) => {},
    shadeDays: (selectedID) => {},
    unshadeDays: () => {},
    handleDaySelected: (e) => {},
    handleSelectTime:(e) => {},
    submitAppointment: () => {},

});

export function AppointmentContextProvider(props){
  //const navigate = useNavigate();
  const [data,setData] = useState({});
  const [selectedMarket,setSelectedMarket] = useState('');
  const [marketIndex,setMarketIndex] = useState(0);
  const [displayStoreDetails,setDisplayStoreDetials] = useState(false);
  const [selectedDates,setSelectedDates] = useState(['March 12, from 9AM till 6 PM','March 12, from 9AM till 6 PM','March 12, from 9AM till 6 PM']);
  const [displayTimes,setDisplayTimes] = useState(false);
  const [selectedTimes,setSelectedTimes] = useState([]);
  const[errors,setErrors] = useState([]);
  const[successMessage,setSuccessMessage] = useState('');
  const [error1,setError1] = useState('');
  const [error2,setError2] = useState('');
  const [error3,setError3] = useState('');
  const [error4,setError4] = useState('');
  const [error5,setError5] = useState('');
  const [error6,setError6] = useState('');
  const [phoneNumber,setPhoneNumber] = useState('');
  const [storeName,setStoreName] = useState('');
  const [storeAddress,setStoreAddress] = useState('');
  const [personName,setPersonName] = useState('');
  const [storeEmail,setStoreEmail] = useState('');
  const [storePosition,setStorePosition] = useState('');
  const [selectedDay,setSelectedDay] = useState([]);
  const [selectedTime,setSelectedTime] = useState([]);
  const navigate = useNavigate();

useEffect(()=> {
  console.log('selectedtime in the contextapi ',selectedTime);
},[selectedTime])
useEffect(()=> {
  console.log('selectedday in the contextapi ',selectedDay);
},[selectedDay])
  

  function handleMarketSelect(e) {
    if(e.target.checked) {
      context.setSelectedMarket(e.target.value);
      // set the selected index of the market
      context.setMarketIndex(e.target.getAttribute('data-index'));
      context.setSelectedDates(e.target.getAttribute('data-selecteddates').split(';'))
      shadeMarkets(e.target.getAttribute('data-selectedmarket'));
      
    }
    else {
      unshadeMarkets();
    }
  }

  function shadeDays(selectedID) {
    const daysNumbers = document.getElementsByClassName('card-input').length;
      for(var i=0;i<daysNumbers;i++) {
        if(selectedID!=document.getElementsByClassName('card-input')[i].parentElement.getAttribute('id')){
          document.getElementsByClassName('card-input')[i].parentElement.classList.add('shaded');
          document.getElementsByClassName('card-input')[i].setAttribute('disabled','disabled');
          document.getElementsByClassName('card-input')[i].checked = false;
        }
      }
      setDisplayTimes(true);
  }

  function unshadeDays() {
    const daysNumbers = document.getElementsByClassName('card-input').length;
      for(var i=0;i<daysNumbers;i++) {
          document.getElementsByClassName('card-input')[i].parentElement.classList.remove('shaded');
          document.getElementsByClassName('card-input')[i].removeAttribute('disabled');
      }
      setDisplayTimes(false);
  }
  function handleDaySelected(e){
    unshadeDays();
    if(e.target.checked) {
      context.setSelectedDay(e.target.value);
      shadeDays(e.target.getAttribute('data-day'));
    }
  }


  function shadeMarkets(selectedID) {
    const marketNumbers = document.getElementsByClassName('hover-img').length;
      for(var i=0;i<marketNumbers;i++) {
        if(selectedID!=document.getElementsByClassName('hover-img')[i].getAttribute('id')){
          document.getElementsByClassName('hover-img')[i].classList.add('shaded');
          document.getElementsByClassName('hover-img')[i].querySelector('input').setAttribute('disabled','disabled');
        }
      }
      setDisplayStoreDetials(true);
  }

  function unshadeMarkets() {
    const marketNumbers = document.getElementsByClassName('hover-img').length;
      for(var i=0;i<marketNumbers;i++) {
          document.getElementsByClassName('hover-img')[i].classList.remove('shaded');
          document.getElementsByClassName('hover-img')[i].querySelector('input').removeAttribute('disabled');
      }
      setDisplayStoreDetials(false);
      setDisplayTimes(false);
  }
  function handleDaySelected(e){
    if(e.target.checked) {
      context.setSelectedTimes(context.data.markets[context.marketIndex].selectTimes[e.target.getAttribute('data-index')].time)
      context.setSelectedDay(e.target.value);
      shadeDays(e.target.getAttribute('data-day'));
    }
    else {
      unshadeDays();
    }
  }

  function handleSelectTime(e){
    const selectedTimId = e.target.getAttribute('id');
    const selectedTimeValue = e.target.value;
    document.getElementsByClassName('timeselected')[0]?.classList.remove('timeselected');
    document.getElementById(selectedTimId).classList.add('timeselected');
    context.selectedTime = selectedTimeValue;
  }

  

  function validateIfEmpty(object){
    if(object.value!='') {
      object.classList.remove('error');
    }
    else {
      object.classList.add('error');
      return true;
    }
    return false;
  }

  function validatePhone(object){
    if(object.value!='') {
      object.classList.remove('error');
    }
    if(object.value.length>13) {
      object.classList.remove('error');
    }
    else {
      object.classList.add('error');
      return true;
    }
    return false;
  }

  function validateIfEmail(object){
    if(object.value!='') {
      object.classList.remove('error');
    }
    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(object.value)){
      object.classList.remove('error');
    }
    else {
      object.classList.add('error');
      return true;
    }
    return false;
  }

  function submitAppointment(){
    
    /*if(context.storeName == '' 
    || context.selectedDay == '' 
    || context.selectedTime == '' 
    || context.storeAddress == '' 
    || context.storeEmail == '' 
    || context.personName == ''
    || context.storePhoneNumber == ''
    || context.storePosition == '') {
      alert('All fields are required, please fill the required information above');
      return false;
    }

    else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(context.storeEmail)){
      alert('Please insert a valid email');
      return false;
    }
    else if(context.storePhoneNumber.length<13) {
      alert('Invalid phone number, please insert a valid phone number');
      return false;
    }*/
    
   //alert("selected Market: " + context.selectedMarket);
   //alert("selected day: " + context.selectedDay);
   //alert("selected time: " + context.selectedTime);
   //alert("store name: " + context.storeName);
   //alert("store Address: " + context.storeAddress);
   //alert("store Email: " + context.storeEmail);
   //alert("person name: " + context.personName);
   //alert("store PhoneNumber: " + context.storePhoneNumber);
   //alert("store Position: " + context.storePosition);
 
   var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "store_name": context.storeName,
  "store_address": context.storeAddress,
  "name": context.personName,
  "email": context.storeEmail,
  "phone": context.storePhoneNumber,
  "position": context.storePosition,
  "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  "dates": context.selectedDay
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};
/*   var formdata = new FormData();
formdata.append("store_name",context.storeName);
formdata.append("store_address",  context.storeAddress);
formdata.append("name", context.personName);
formdata.append("email", context.storeEmail);
formdata.append("phone", context.storePhoneNumber);
formdata.append("position",  context.storePosition);
formdata.append("dates[]", context.selectedDay);
//formdata.append("time[]", context.selectedTime);
formdata.append("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};
*/
fetch(`https://events.jiwcalendar.com/api/forms/2/events/4/submissions`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.success) {
      setErrors([]);
      navigate("/confirmation/"+result.data.id);
      //navigate("/confirmation");

    }
    else {
    setErrors(result.data)
    setSuccessMessage('');
    }
   })
  .catch(error => console.log(error));
}

    const context = {
        data: data,
        setData: setData,
        selectedMarket: selectedMarket,
        marketIndex: marketIndex,
        setMarketIndex:setMarketIndex,
        setSelectedMarket: setSelectedMarket,
        displayStoreDetails: displayStoreDetails,
        selectedDates:selectedDates,
        setSelectedDates:setSelectedDates,
        displayTimes:displayTimes,
        selectedTimes:selectedTimes,
        setSelectedTimes:setSelectedTimes,
        selectedDay:selectedDay,
        setSelectedDay:setSelectedDay,
        selectedTime:selectedTime,
        setSelectedTime:setSelectedTime,
        errors:errors,
        successMessage:successMessage,
        error1:error1,
        setError1:setError1,
        error2:error2,
        setError2:setError2,
        error3:error3,
        setError3:setError3,
        error4:error4,
        setError4:setError4,
        error5:error5,
        setError5:setError5,
        error6:error6,
        setError6:setError6,
        storeName:storeName,
        setStoreName:setStoreName,
        storeAddress:storeAddress,
        setStoreAddress:setStoreAddress,
        personName:personName,
        setPersonName:setPersonName,
        storeEmail:storeEmail,
        setStoreEmail,setStoreEmail,
        storePhoneNumber: phoneNumber,
        setStorePhoneNumber: setPhoneNumber,
        storePosition:storePosition,
        setStorePosition:setStorePosition,
        validateIfEmpty: validateIfEmpty,
        validatePhone: validatePhone,
        validateIfEmail: validateIfEmail,
        handleMarketSelect: handleMarketSelect,
        shadeDays: shadeDays,
        unshadeDays: unshadeDays,
        handleDaySelected: handleDaySelected,
        handleSelectTime:handleSelectTime,
        submitAppointment:submitAppointment

    };


    // method to format the date as following Sun, December 09, 2023

    

    return <AppointmentContext.Provider value={context}>
        {props.children}
    </AppointmentContext.Provider>
}

export default AppointmentContext;