import React from "react";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import confirm from "../../assets/images/confirm.svg";
import footer from "../../assets/images/footer.svg";
import logo from "../../assets/images/logo.svg";
import calender from "../../assets/images/calender.svg";
import bride from "../../assets/images/first.webp";
import bride2 from "../../assets/images/second.webp";
import bride3 from "../../assets/images/third.webp";
import { atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

function Confirmation() {
  const [os,setOs] = useState();
  const [options,setOptions] = useState();
  const [data,setData] = useState();
  const [loading,setLoading] = useState();

  let { id } = useParams();
  function getOS() {
    let os = navigator.userAgent;
    let finalOs="";
    if (os.search('Windows')!==-1){
        finalOs="Windows";
    }
    else if (os.search('Mac')!==-1){
        finalOs="MacOS";
    }
    else if (os.search('Macintosh')!==-1){
      finalOs="Macintosh";
    }
    else if (os.search('iPhone')!==-1){
      finalOs="iPhone";
    }
    else if (os.search('Android')!==-1){
      finalOs="Android";
    }
    else if (os.search('X11')!==-1 && !(os.search('Linux')!==-1)){
        finalOs="UNIX";
    }
    else if (os.search('Linux')!==-1 && os.search('X11')!==-1){
        finalOs="Linux"
    }
    return finalOs
  }

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch("https://events.jiwcalendar.com/api/submissions/"+id, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log('results',result);
        setData(result.data)
      }
        )

      .catch(error => console.log('error', error));
  }, []);

  useEffect(() => {
    if(data){
      setOs(getOS());
    }
  },[data])

  useEffect(() => {
      if(os=='MacOS' || os=='Macintosh' || os=='iPhone'){
        setOptions('"Apple"');
      }
      if(os=='Windows' || os=='Android' || os=='UNIX' || os=='Linux'){
        setOptions('"Google"');
      }
    
    
  },[os])

  useEffect(() => {
    atcb_init();
  },[options])

  return (
    <>
    {data &&
    <div className="confirmBackground">
      <header className="header position-relative">
        <img src={logo} alt="logo" className="position-absolute margin-top" />
      </header>
      <div className="section-display pt-5 ">
        <div className="calender mt-2 col-lg-6 col-md-6 col-sm-12">
          <div className="text-center">
            <img src={calender} alt="calender" />
          </div>
          <h3 className="confirm-title mt-4 ">Appointment Requested</h3>
          <p className="confirm-text mt-4 mb-5">
          We will call you soon to confirm an appointment from these selected times for an Elissar store visit at your store location.
          </p>

          {
            data.dates.map((selected,index) => {
            return(
              <>
               <div className="border-date ">
            <p className="confirm-title mt-4">{selected.selectedTime}</p>
            <p className="confirm-text">{selected.selectedDate}</p>
          </div>
          <br />
          </>
          )
            
            })
          }
         
         

        </div>

       
      </div>
      <img
        src={confirm}
        alt=""
        className="bride-cover margintop heightConfirm"
      />
      <footer>
        <img src={footer} alt="" className="bride-cover" />
      </footer>
    </div>
  }
    </>
  );
}

export default Confirmation;
